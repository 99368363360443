import skillslogo from "../../../Images/Common/Skills_Nd_Skills_website_image-01-removebg-preview.png";
import "../../../CSS/Home.css";
import "../../../CSS/main.css";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import OurCurrentPrograms from "./OurCurrentPrograms";
import Shimmer from "../../Common/ShimmerUI/Shimmer";
import { Blurhash } from "react-blurhash";
import MetaTags from "../../MetaInfo/MetaTags";

const Home = () => {
  const [loading, setLoading] = useState(true);
  const [imgLoading, setImgLoading] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, []);

  useEffect(() => {
    const img = new Image();
    img.onload = () => {
      setImgLoading(true);
    };
    img.src = skillslogo;
  }, []);

  return (
    <>
      <MetaTags
        title={"Skill Development Courses in Telangana -New Directions Skills"}
        Description={
          "Discover top skill development courses in Telangana at ND Skills. Enhance your career with DDU GKY-certified programs. Boost your employability today!"
        }
        Keywords={
          "Skill Development Courses in Telangana    Free Seo Course in Telangana   ddu-gky courses    ND Skills Training Programs    Employability Skills Telangana"
        }
        href={"https://ndskills.in/"}
        OgTitle={"Skill Development Courses in Telangana -ND Skills"}
        OgUrl={"https://ndskills.in/"}
        OgDescription={
          "Discover top skill development courses in Telangana at ND Skills. Enhance your career with DDU GKY-certified programs. Boost your employability today!"
        }
        OgImg={
          "https://ndskills.in/static/media/Skills_Nd_Skills_website_image-01-removebg-preview.3881a10a60f31d0e3dfa.png"
        }
        twitterImage={
          "https://ndskills.in/static/media/Skills_Nd_Skills_website_image-01-removebg-preview.3881a10a60f31d0e3dfa.png"
        }
        twitterTitle={
          "Skill Development Courses in Telangana -New Directions Skills"
        }
        twitterDescription={
          "Discover top skill development courses in Telangana at ND Skills. Enhance your career with DDU GKY-certified programs. Boost your employability today!"
        }
      />
      <div>
        {loading ? (
          <Shimmer />
        ) : (
          <>
            <div className="d-flex align-items-center bg-light ">
              <marquee
                width="100%"
                direction="left"
                height="30px"
                scrollamount="15"
                className="d-flex align-items-center"
                style={{ cursor: "pointer" }}
              >
                <Link to="/jobs" className=" text-decoration-none">
                  <span className="text-dark mx-2 ">
                    🚀 ND Skills is now connecting talents globally with
                    International Hiring. Explore global opportunities today!
                    🌍✨
                  </span>
                </Link>
              </marquee>
            </div>{" "}
            <div className="d-flex flex-md-row flex-column-reverse justify-center align-items-center ">
              <div className="d-flex flex-column align-items-start gap-2 skills-inner-div description">
                <h1 id="home-heading">Be Future-Ready!</h1>
                <p id="home-para">
                  Transform Your Career with ND Skills. Sign Up Now and Get
                  Trained in In-Demand Skills!
                </p>
                <button className="rounded-1 btn1 fs-5 py-3 px-4">
                  <Link
                    to="/Contactus"
                    className="text-decoration-none text-white"
                  >
                    Enroll Today
                  </Link>
                </button>
              </div>
              <div className="skills-inner-div">
                {!imgLoading && (
                  <Blurhash
                    hash="LAODa%Tf^keSpxZ~j[sm~qV?D$XT"
                    height={253}
                    punch={1}
                    className="img-fluid w-100 skills-img"
                  />
                )}
                {imgLoading && (
                  <img
                    src={skillslogo}
                    alt="skills img"
                    className="img-fluid w-100 skills-img"
                  />
                )}
              </div>
            </div>
          </>
        )}

        <OurCurrentPrograms />
        {/* <MissionVisionValues /> */}
        {/* <OurAchievements /> */}
        {/* <Affiliations /> */}
        {/* <VideoTestimonials /> */}
      </div>
    </>
  );
};

export default Home;
