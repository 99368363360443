import React, { useEffect, useState } from "react";
import comingsoon from "../../Images/ComingSoon/coming-soon.jpg";
import Shimmer from "../Common/ShimmerUI/Shimmer";
import { Blurhash } from "react-blurhash";
import MetaTags from "../MetaInfo/MetaTags";
import SkilledLabourImg from "../../Images/Blog/skilledLabour.jpg";
import { Link, useNavigate } from "react-router-dom";
import "../../CSS/blog.css";
import "../../CSS/main.css";

const Blog = () => {
  const [loading, setLoading] = useState(true);
  const [imgLoading, setImgLoading] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);
  useEffect(() => {
    const img = new Image();
    img.onload = () => {
      setImgLoading(true);
    };
    img.src = comingsoon;
  });

  const handleNavigation = () => {
    navigate("/blog-view");
  };

  return (
    <>
      <MetaTags
        title={"Educational Blog - Insights and Tips from ND Skills"}
        Description={
          "Explore the ND Skills Educational Blog for expert insights, training tips, career development advice, and the latest industry news. Stay informed and inspired!"
        }
        Keywords={"Educational Blog"}
        href={"https://ndskills.in/Blog"}
      />
      <div className="d-flex flex-row gap-4 pt-4 px-4">
        <div className="blog-post">
          <img
            src={SkilledLabourImg}
            className="img-fluid"
            onClick={handleNavigation}
          />
          <h1 className=" blog-title py-2 ">
            <Link className=" text-decoration-none textColor2" to="/blog-view">
              Skilled Labour Export from India: Challenges and Opportunities –
              An Analysis
            </Link>
          </h1>
          <p className="date">March 21, 2025</p>
        </div>
      </div>
    </>
  );
};

export default Blog;
