import React from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import "../../CSS/main.css";
import MetaTags from "../MetaInfo/MetaTags";
const CancellationRefundPolicy = () => {
  return (
    <>
      <MetaTags
        title={"ND Skills Refund Policy: What You Need to Know"}
        Description={
          "Learn how to get your money back with ND Skills' refund policy. Understand eligibility, timelines, and procedures for full payments and installments."
        }
        Keywords={
          "ND Skills refund policy  Refund eligibility ND Skills   How to get a refund ND Skills   Get your money back ND Skills   ND Skills loan refunds"
        }
        href={"https://ndskills.in/CancellationRefundPolicy"}
      />
      <Container>
        <Row>
          <Col>
            <Card className=" bg-body-tertiary p-2 p-md-5 fontStyle">
              <h1 className="py-2 fs-2 textColor">Refund Terms</h1>
              <h5 className="textColor2 pb-2 fw-semibold">
                Full Payment Plan:
              </h5>
              <p>
                The Candidate can request for a full refund of the Program Fee,
                within 7 days from the Learning Commencement Date or 15 days
                from fee payment date, whichever is earlier.
              </p>
              <h5 className="textColor2 pb-2 fw-semibold">
                Monthly Instalment Plan:
              </h5>
              <p>
                The Candidate can request the ND Skills for refund within 7 days
                from the Learning Commencement Date or 15 days from the LMS
                setup date, whichever is earlier and they shall receive the
                refund amount in the manner calculated below. And further, the
                Candidate’s Monthly Instalments gets cancelled and is not
                further liable to repay the Monthly Instalments.
              </p>
              <p>
                {" "}
                Refund Amount = Monthly Instalments paid (if any) - Foreclosure
                Charges
              </p>{" "}
              <p>
                {" "}
                Further in case of Monthly Instalments Plan, the Candidate fails
                to request a refund within the mentioned term then the Candidate
                will not be entitled for refund and the Candidate will also be
                liable to pay the amount due as per schedule. If the Candidate
                still wishes to cancel the Monthly Instalments, then they shall
                complete the formalities on their own by paying closure charges
                if any and other dues. The ND Skills shall neither facilitate
                nor be responsible for Monthly Instalments closure in any manner
                in such cases.
              </p>
              <h5 className="textColor2 pb-2 fw-semibold">
                Loan Payment Plan:
              </h5>
              <p>
                If the Candidate requests the ND Skills for refund within 7 days
                from the Learning Commencement Date or 15 days from the LMS
                setup date, whichever is earlier, they shall receive the refund
                amount in the manner calculated below. Further, the Candidate’s
                Loan also gets cancelled and Candidate is not further liable to
                repay the Loan.
              </p>
              <p>
                {" "}
                Refund Amount = Monthly Instalments paid (if any) - Foreclosure
                Charges{" "}
              </p>
              <p>
                {" "}
                Further, if the Candidate fails to request a refund within the
                mentioned term, then the Candidate will not be entitled for
                refund and the candidate will also be liable to repay the Loan
                to the Loan Provider. If the Candidate still wishes to foreclose
                the Loan, then they shall complete the formalities on their own
                by paying foreclosure charges and other dues to Loan Providers.
                The ND Skills shall neither facilitate nor be responsible for
                Loan closure in any manner in such cases.
              </p>
              <p>
                {" "}
                If a student is eligible to claim refund or loan closure, a loan
                closure request will be processed within 45 working days after
                submission of loan closure request from registered email id to
                support@ndskills.in. Any eligible refund shall be processed as
                per the terms in refund procedure except refund timelines
                mentioned therein.
              </p>
              <h5 className="textColor2 pb-2 fw-semibold">
                Periodic Instalments Payment Plan:
              </h5>
              <p>
                If the Candidate requests the ND Skills for refund of the first
                instalment amount within 7 days from the Learning Commencement
                Date or 15 days from the LMS setup date, whichever is earlier,
                they shall receive the full refund of the amount paid. Any
                subsequent instalments paid by the Candidate are non-refundable.
              </p>
              <p>
                A refund request shall be raised by the Candidate as per the
                terms in refund procedure subject to fulfilling the eligibility
                criteria for refund.
              </p>
              <p>
                You hereby agree to receive communication related to available
                discounts/offers/benefits on Course/Program Fees from ND Skills
                from time to time. Such discounts/offers/benefits are subject to
                certain terms and conditions. The decision of the ND Skills
                shall be final and binding in this regard.
              </p>
              <h3 className="py-2 textColor">Refund Procedure</h3>
              <p>
                The Candidate can raise a refund request by sending an email
                from their registered email ID to support@ndskills.in by
                attaching a reference for the payment made (payment receipt or
                payment ID or transaction screenshot). Any request for refund
                shall only be made to above mentioned email ID.
              </p>
              <p>
                The refund request will be processed by the ND Skills in 2-15
                working days after receiving the request subject to receiving
                all the required information from the Candidate.{" "}
              </p>
              <p>
                Once the refund request is processed, a pay-out link along with
                the details of refund amount will be sent by third-party service
                provider Razorpay to Candidate’s registered mobile number.
              </p>
              <p>
                Candidate needs to login with their registered mobile number and
                OTP (sent to registered mobile number) and provide bank account
                details or UPI ID to which refund amount needs to be credited.
                The Candidate shall strictly ensure that they enter correct
                bank/UPI details. Submission of incorrect details will result in
                the amount getting credited to the wrong account. Once the
                amount is credited, whether to correct or incorrect account, ND
                Skills shall consider the refund request as closed.
              </p>
              <p>
                ND Skills shall not be liable for any loss, whether direct or
                indirect, resulting from entering wrong bank account details
                (or) UPI ID.
              </p>
              <p>
                Candidate will be receiving confirmation message from Razorpay
                after the amount is credited to the bank account.
              </p>
              <p>
                Payout link will get expired in 72 hrs after sending the link by
                the ND Skills to their registered email id. ND Skills is not
                liable to process the refund if the Candidate doesn’t claim the
                refund before the expiry period. ND Skills shall close the
                refund request after the mentioned expiry period.{" "}
              </p>
              <p>
                In case of any queries related to payments, you can get in touch
                with us at support@ndskills.in (or) +91 88857 11171. The payment
                gateway mechanisms are governed by the terms and conditions of
                the third-party providers. For your reference, here is the
                payment gateway refund policy.
                <a
                  href="https://razorpay.com/payment-gateway/"
                  className=" text-decoration-none"
                >
                  click here
                </a>
              </p>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default CancellationRefundPolicy;
