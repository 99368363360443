import React, { createContext, useEffect, useState } from "react";
import Header from "./Components/Common/Header";
import About from "./Components/Pages/About/About";
import { BrowserRouter, Routes, Route } from "react-router-dom";
// import SchoolsPrograms from "./Components/Pages/Schools- Programs";
import Blog from "./Components/Pages/Blog";
import Jobs from "./Components/Pages/Jobs";
import Home from "./Components/Pages/Home/Home";
import ContactUs from "./Components/Pages/Contact Us";
import Gallery from "./Components/Pages/Gallery";
import SchoolOfDataScience from "./Components/Pages/Schools- Programs/SchoolOfDataScience";
import SchoolOfFashion from "./Components/Pages/Schools- Programs/SchoolOfFashion";
import SchoolOfHealthCare from "./Components/Pages/Schools- Programs/SchoolOfHealthCare";
import SchoolOfBuildingAndAutomation from "./Components/Pages/Schools- Programs/SchoolOfBuildingAndAutomation";
import "bootstrap/dist/css/bootstrap.min.css";
import Footer from "./Components/Common/footer";
import Terms from "./Components/Common/Terms";
import Governance from "./Components/Common/Governance";
import "aos/dist/aos.css";
import CancellationRefundPolicy from "./Components/Common/CancellationRefundPolicy";
import Popup from "./Components/Common/Pop";
import OurOrganisation from "./Components/Pages/About/OurOrgainsation/OurOrganisation";
import AdvisoryBoard from "./Components/Pages/About/AdvisoryBoard";
import ManagementTeam from "./Components/Pages/About/ManagementTeam";
import FounderProfile from "./Components/Pages/About/FounderProfile/FounderProfile";
import Donation from "./Components/Pages/Donation";
import BlogView from "./Components/Pages/BlogView";

export const ContextStore = createContext();

const App = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [isImgLoaded, setIsImgLoaded] = useState(false);

  return (
    <ContextStore.Provider
      value={{ isImgLoaded, setIsImgLoaded, isVisible, setIsVisible }}
    >
      <BrowserRouter>
        <Header />
        <Popup />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/ourOrganisation" element={<OurOrganisation />} />
          <Route path="/advisoryBoard" element={<AdvisoryBoard />} />
          <Route path="/founderProfile" element={<FounderProfile />} />
          <Route path="/managementTeam" element={<ManagementTeam />} />
          <Route path="/About" element={<About />} />
          <Route path="/Jobs" element={<Jobs />} />
          <Route path="/Gallery" element={<Gallery />} />
          <Route path="/Blog" element={<Blog />} />
          <Route path="/Contactus" element={<ContactUs />} />
          <Route path="/School-of-fashion" element={<SchoolOfFashion />} />
          <Route
            path="/School-of-datascience"
            element={<SchoolOfDataScience />}
          />

          <Route
            path="/School-of-building-automation"
            element={<SchoolOfBuildingAndAutomation />}
          />

          <Route
            path="/School-of-healthcare"
            element={<SchoolOfHealthCare />}
          />
          <Route
            path="/CancellationRefundPolicy"
            element={<CancellationRefundPolicy />}
          />
          <Route path="/terms" element={<Terms />} />
          <Route path="/governace" element={<Governance />} />
          <Route path="/donation" element={<Donation />} />
          <Route path="/blog-view" element={<BlogView />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </ContextStore.Provider>
  );
};

export default App;
