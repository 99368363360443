import React from "react";
import { BlogContent } from "../utils";
import { Container, Card, Col, Row } from "react-bootstrap";
import "../../CSS/main.css";
import MetaTags from "../MetaInfo/MetaTags";

const BlogView = () => {
  return (
    <>
      {" "}
      <MetaTags
        title={
          "Skilled Labour Export from India: Challenges and Opportunities – An Analysis"
        }
        Description={
          "Explore the challenges and opportunities in India's skilled labour export, focusing on IT, healthcare, engineering, and education, and strategies for growth."
        }
        Keywords={"Educational Blog"}
        href={"https://ndskills.in/BlogView"}
      />
      <Container className="mt-5">
        {BlogContent.map((blog) => (
          <div key={blog.id} className="mb-5">
            {/* Blog Title */}
            <div className=" Blog-div">
              <div className="">
                <h1 className=" textColor2">{blog.title}</h1>
                <p className="text-muted">{blog.content}</p>
              </div>
              <img src={blog.img1} alt={blog.img1} className="img-fluid " />
            </div>

            {/* Sections Mapping */}
            {blog.sections.map((section, secIndex) => (
              <div key={secIndex} className="mt-4">
                <h2 className="textColor2 sectionLabel">{section.title}</h2>

                {/* Subsections Mapping */}
                <Row className="gy-3">
                  {section.subSections.map((sub, subIndex) => (
                    <Col md={12} key={subIndex}>
                      <h4 className="subhead">{sub.subhead}</h4>
                      <p>{sub.description}</p>
                    </Col>
                  ))}
                </Row>
                {section.img2 && section.img3 && (
                  <div className=" sub-images ">
                    <img
                      src={section.img2}
                      alt="IT Img"
                      className="img-fluid object-fit-contain"
                    />
                    <img
                      src={section.img3}
                      alt=" Healthcare Img"
                      className="object-fit-contain img-fluid"
                    />
                  </div>
                )}
              </div>
            ))}

            {/* Conclusion */}
            <div className="mt-4 bg-light px-4 py-2">
              <h2 className="textColor2">Conclusion</h2>
              <p>{blog.conclusion}</p>
            </div>
          </div>
        ))}
      </Container>
    </>
  );
};

export default BlogView;
