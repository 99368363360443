import React, { useContext, useEffect, useState } from "react";
import comingsoon from "../../Images/ComingSoon/coming-soon.jpg";
import Shimmer from "../Common/ShimmerUI/Shimmer";
import { FaLocationDot } from "react-icons/fa6";
import { IoIosPeople } from "react-icons/io";
import MetaTags from "../MetaInfo/MetaTags";
import "../../CSS/About.css";
import "../../CSS/main.css";
import { Link } from "react-router-dom";
import ProcessImg from "../../Images/Jobs/process.jpg";
import SolarPanelInstallationImg from "../../Images/Jobs/solar-panels-installation2.png";
import SolarPanelInstallationTitle from "../../Images/Jobs/SolarPanelInstallationTechnician.png";
import WhyGermany from "../../Images/Jobs/Why-Germany.png";
import ReactPlayer from "react-player";
import "../../CSS/main.css";
import "../../CSS/Jobs.css";
import { ContextStore } from "../../App";

const Jobs = () => {
  const [loading, setLoading] = useState(true);
  const { isImgLoaded, setIsImgLoaded, isVisible, setIsVisible } =
    useContext(ContextStore);

  console.log(isVisible);

  useEffect(() => {
    const timerId = setTimeout(() => {
      setLoading(false);
      setIsVisible(false);
    }, 2000);

    return () => clearTimeout(timerId);
  }, []);

  useEffect(() => {
    const img = new Image();
    img.onload = () => {
      setIsImgLoaded(true);
    };
    img.src = comingsoon;
  }, []);

  return (
    <>
      <MetaTags
        title={"Explore Job Opportunities and Enhance Your Skills - ND SKIlls"}
        Description={
          "Find job opportunities tailored to your skills with ND Skills. Connect with top employers and access professional training. Start your career journey!"
        }
        Keywords={"Job Opportunities"}
        href={"https://ndskills.in/Jobs"}
      />
      {loading ? (
        <Shimmer />
      ) : (
        <div className="mt-4">
          <div className="h-75">
            <img
              src={SolarPanelInstallationImg}
              alt="Solar Panel Installation Img"
              className="w-100 h-100 object-fit-cover"
            />
          </div>
          <div className="container my-4">
            <div className="row ">
              <div className=" col-md-12 ">
                <div
                  className="position-relative  w-100 shadow-sm rounded d-flex justify-content-center align-items-center "
                  style={{
                    backgroundImage: `url(${WhyGermany})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    height: "100px",
                    objectFit: "contain",
                  }}
                >
                  <Link
                    to="https://www.youtube-nocookie.com/embed/82LBS8zdL9c"
                    className="btn btn-light fw-semibold position-absolute  watchNow shadow"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className="bi bi-play-circle-fill "></i> Watch Now
                  </Link>
                </div>
              </div>
            </div>
          </div>

          <div className=" job">
            <div className="shadow rounded-2 border border-1 p-4">
              <div className="d-flex title justify-content-around align-items-center">
                <img
                  src={SolarPanelInstallationTitle}
                  alt="Solar Panel Installation Title"
                  className="imgTitle "
                />
              </div>

              <h3 className="textColor2 fw-medium fs-4">Job Description</h3>
              <p className=" text-secondary">
                We are looking for skilled Solar PV Installers to join for
                various positions in the German renewable industry. These
                on-site roles require hands-on experience in electrical/solar
                installations and physical fitness. The positions involve
                installing, testing, and maintaining photovoltaic (solar)
                systems on private and commercial properties across Germany.
              </p>
              <h5 className="textColor2">Key Responsibilities:</h5>
              <ul className=" text-secondary">
                <li>
                  Plan, execute, and test electrical installations for solar PV
                  systems.
                </li>
                <li>
                  Install DC-side components, including mounting structures,
                  solar modules, and related cabling.
                </li>
                <li>
                  Perform AC-side electrical installations, including inverters,
                  battery storage, and charging stations.
                </li>
                <li>Ensure compliance with safety and quality standards.</li>
                <li>
                  Collaborate with electrical specialists and team members.
                </li>
                <li>
                  Conduct system testing, troubleshooting, and maintenance.
                </li>
                <li>Document installation procedures and protocols.</li>
              </ul>
            </div>
            <div className=" shadow rounded-2 border border-1 p-4">
              <h3 className="textColor2 fw-medium fs-4 "> Job Details</h3>
              <span className="text-secondary d-flex flex-row gap-2 pb-2">
                <span>
                  <IoIosPeople size={25} />
                </span>
                Openings: 60+
              </span>
              <span className="text-secondary d-flex flex-row gap-2 pb-4">
                <span>
                  <FaLocationDot size={20} />
                </span>
                Germany
              </span>
              <div>
                <h3 className="textColor2 fw-medium fs-4">Qualification</h3>
                <p className="text-secondary">
                  🎓 Diploma/Btech/ Degree in Electronics & Communication (ECE),
                  Electrical & Electronics (EEE), Mechanical, or Civil
                  Engineering.
                </p>

                <h3 className="textColor2 fw-medium fs-4">Eligibility</h3>
                <ul className="text-secondary">
                  <li>✅ Academic record with minimum 60% and above </li>
                  <li>
                    ✅ Experience: Relevant experience is preferred but not
                    mandatory.
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className=" applyNow my-2 mb-3 my-md-4 mb-md-5">
            <Link
              to="https://forms.gle/wV5oBJCmqzdoAK467"
              className="text-white text-decoration-none text-center fw-medium "
            >
              <button className="rounded btn1 px-4 p-2 px-md-5 p-md-3   ">
                Apply Now
              </button>
            </Link>
          </div>

          {/* ***************** Application Process ***************** */}
          <div className=" w-full application   backGroundColor">
            <div className="bg-white application-innerdiv  shadow-lg  rounded border border-gray-200  max-w-2xl mx-auto">
              <h3 className="textColor2 font-semibold  mb-4">
                Application & Training Process
              </h3>
              <div className="mb-2">
                <h5 className="font-bold textColor2">
                  Stage 1: Application Submission
                </h5>
                <p className="text-gray-700">
                  Submit your application along with the required documents for
                  initial screening.
                </p>
              </div>
              <div className="mb-2">
                <h5 className="font-bold textColor2">
                  Stage 2: Certificates Verification
                </h5>
                <p className="text-gray-700">
                  Your academic certificates will be verified to meet required
                  standards.
                </p>
              </div>
              <div className="mb-2">
                <h5 className="font-bold textColor2">
                  Stage 3: Preliminary Interview & Conditional Offer
                </h5>
                <p className="text-gray-700">
                  Begin professional training tailored to your role, alongside
                  German language orientation for smooth integration.
                </p>
              </div>
              <div className="mb-2">
                <h5 className="font-bold textColor2">
                  Stage 4: Domain-Specific Training, IELTS-Based English
                  Proficiency Assessment & Language Orientation{" "}
                </h5>
                <p className="text-gray-700">
                  This stage involves specialized training in your field, along
                  with an English proficiency assessment through IELTS and
                  German language orientation. Upon successful completion, you
                  will receive certification, helping you proceed with your visa
                  application.
                </p>
              </div>
              <div className="mb-2">
                <h5 className="font-bold textColor2">
                  Stage 5: Visa Processing & Relocation Preparation
                </h5>
                <p className="text-gray-700">
                  Once the visa application is submitted, you will receive
                  guidance on documentation and interview preparation. Our team
                  will assist you for a smooth process. After visa approval, you
                  will be ready to relocate and begin your journey in Germany!
                </p>
              </div>
              <div className="p-1 p-md-3 border-start border-4 border-danger bg-light shadow-sm rounded">
                <p className="text-danger fw-semibold d-flex align-items-start gap-2">
                  <strong>Important Note:</strong>
                </p>
                <p className="text-danger fw-semibold d-flex align-items-start gap-2">
                  <span>📌</span> Stage 4 is a paid training program.
                </p>
                <p className="text-danger fw-semibold d-flex align-items-start gap-2 mt-2">
                  <span>📌</span> Candidates with verified experience in the
                  renewable energy (solar) industry are exempt from domain
                  training.
                </p>
                <p className="text-danger fw-semibold d-flex align-items-start gap-2 mt-2">
                  <span>📌</span> English language test scores may be required
                  at BSW Solar's discretion following the preliminary
                  orientation.
                </p>
              </div>
            </div>
            <div className=" bg-white shadow-md rounded-2  application-innerdiv border-1 rounded d-flex flex-column  ">
              <h3 className=" font-semibold pb-4">
                Our <span className="textColor2"> Process</span>
              </h3>
              <img src={ProcessImg} alt="Process Img" className="w-100" />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Jobs;
