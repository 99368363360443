import React, { useState, useEffect } from "react";
import { AffiliationsImages } from "../../../utils";
import { Row, Col, Container } from "react-bootstrap";
import { Blurhash } from "react-blurhash";
import "../../../../CSS/Home.css";
import SSELogo from "../../../../Images/Affiliations/SSElogo_proportional.png";
import bselogo from "../../../../Images/Affiliations/bselogo_proportional.png";
const Affiliations = () => {
  const [imgLoading, setImgLoading] = useState(
    AffiliationsImages.reduce((acc, { key }) => ({ ...acc, [key]: false }), {})
  );

  useEffect(() => {
    AffiliationsImages.forEach(({ key, src }) => {
      const img = new Image();
      img.onload = () => {
        setImgLoading((prev) => ({ ...prev, [key]: true }));
      };
      img.src = src;
    });
  }, []);

  const divStyle = {
    color: "#183b56",
  };

  return (
    <>
      <h2 className="text-center my-5 title-styling" style={divStyle}>
        Affiliations
      </h2>
      <Container>
        <Row className="text-center d-flex row-gap-4 w-auto mx-auto">
          {AffiliationsImages.map(({ key, src, alt, marginTop, maxWidth }) => (
            <Col
              xs={12}
              sm={6}
              md={3}
              // className="mb-4 affiliation-col affiliation-logo-spacing"
              className="d-flex flex-wrap justify-content-center align-items-center "
              key={key}
            >
              {!imgLoading[key] ? (
                <Blurhash
                  hash="m3Duuf?b008whzs9%gx]0056_3~V*0xFR4570f%2^PIo?uXSRjwI"
                  punch={1}
                  style={{ width: "100%", height: "150px" }}
                  className="img-fluid"
                />
              ) : (
                <img
                  src={src}
                  alt={alt}
                  className="img-fluid logo-width object-fit-contain"
                />
              )}
            </Col>
          ))}
        </Row>
        <Row className="mt-4">
          <Col className="text-center">
            <p className="fs-5 fw-bold mt-2 mb-4" style={divStyle}>
              BSE & NSE Registered NPO
            </p>
            <div className="d-flex justify-content-center align-items-center">
              <div
                className="d-flex justify-content-around align-items-center gap-3 gap-md-5 p-3 flex-column flex-md-row"
                style={{
                  border: "1px solid #ddd",
                  borderRadius: "8px",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                }}
              >
                <img
                  src={SSELogo}
                  alt="SSE-LOGO"
                  style={{
                    height: "50px",
                    width: "auto",
                    objectFit: "contain",
                  }}
                  className="img-fluid"
                />
                <img
                  src={bselogo}
                  alt="BSE-LOGO"
                  style={{
                    height: "50px",
                    width: "auto",
                    objectFit: "contain",
                  }}
                  className="img-fluid"
                />
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Affiliations;
